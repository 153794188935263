import React, { useContext } from 'react'
import Header from './navigation/header'

import '../styles/layout.css'
import styles from './layout.module.css'
import LocaleContext from '../lib/locale-context'

const Layout = props => {
  const { language, setLanguage } = useContext(LocaleContext)

  const {
    children,
    companyInfo,
    onHideNav,
    onShowNav,
    showNav,
    siteTitle,
    navigation,
    logo
  } = props

  return (
    <>
      <Header
        logo={logo}
        siteTitle={siteTitle}
        onHideNav={onHideNav}
        onShowNav={onShowNav}
        showNav={showNav}
        locale={language}
        navigation={navigation}
      />
      {/* {console.log(navigation)} */}
      <div className={styles.content}>{children}</div>
      <footer className={styles.footer}>
        <div className={styles.footerWrapper}>
          {/* <div className={styles.companyAddress}>
            {companyInfo && (
              <div>
                {companyInfo.name}
                <br />
                {companyInfo.address1}
                <br />
                {companyInfo.address2 && (
                  <span>
                    {companyInfo.address2}
                    <br />
                  </span>
                )}
                {companyInfo.zipCode} {companyInfo.city}
                {companyInfo.country && <span>, {companyInfo.country}</span>}
              </div>
            )}
          </div> */}

          <div className={styles.siteInfo}>© {new Date().getFullYear()}, Peluzah</div>
        </div>
      </footer>
    </>
  )
}

export default Layout
