import { Link } from 'gatsby'

import React from 'react'

import Icon from '../icons'
import { cn, buildImageObj } from '../../lib/helpers'
import styles from './header.module.css'
import Flag from 'react-flags'

import { imageUrlFor } from '../../lib/image-url'

const Header = ({ onHideNav, onShowNav, showNav, siteTitle, locale, navigation, logo }) => {
  const altLocale = locale == 'en' ? 'es' : 'en'
  // console.log(Logo)
  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <h1 className={styles.branding}>
          <Link to={'/' + locale}>
            {/* <span>{siteTitle}</span> */}

            {logo && logo.asset && (
              <img
                className={styles.logo}
                src={imageUrlFor(buildImageObj(logo)).width(100).height(120).url()}
                alt="Logo"
              />
            )}
          </Link>
        </h1>

        <button className={styles.toggleNavButton} onClick={showNav ? onHideNav : onShowNav}>
          <Icon symbol="hamburger" />
        </button>

        <nav className={cn(styles.nav, showNav && styles.showNav)}>
          <ul>
            {navigation &&
              navigation.map(({ menuSlug, menuTitle }, key) => {
                // console.log(menuSlug)
                return (
                  <li key={key}>
                    <Link to={menuSlug.current}>{menuTitle}</Link>
                  </li>
                )
              })}
            <li>
              <Link
                className={altLocale === 'es' ? styles.esLocale : styles.enLocale}
                to={'/' + altLocale}
              >
                {altLocale}
              </Link>
              {/* <Flag name="ESP" format="png" pngSize={64} shiny={true} alt="Flag" /> */}
            </li>
          </ul>
        </nav>
      </div>
    </div>
  )
}

export default Header
